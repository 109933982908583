<template>
  <el-main>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="广告价格" name="1">
        <div class="tips">广告价格设置：支持根据广告类型设置价格</div>
        <table>
          <thead>
            <tr>
              <th>*广告价格</th>
              <th>*每个城市展示限制数量</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="price">
                  图片广告
                  <el-input v-model="info.type_1_price"></el-input>
                  元/天
                </div>
              </td>
              <td>
                <el-input v-model="info.type_1_show_number"></el-input>
              </td>
            </tr>
            <tr>
              <td>
                <div class="price">
                  软文广告
                  <el-input v-model="info.type_2_price"></el-input>
                  元/天
                </div>
              </td>
              <td>
                <el-input v-model="info.type_2_show_number"></el-input>
              </td>
            </tr>
            <tr>
              <td>
                <div class="price">
                  视频广告
                  <el-input v-model="info.type_3_price"></el-input>
                  元/天
                </div>
              </td>
              <td>
                <el-input v-model="info.type_3_show_number"></el-input>
              </td>
            </tr>
          </tbody>
        </table>
      </el-tab-pane>
      <el-tab-pane label="广告说明" name="2">
        <RichText :richTxt="info.explains" @soninfo="val => (info.explains = val)"></RichText>
      </el-tab-pane>
    </el-tabs>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    RichText,
    Preservation,
  },
  data() {
    return {
      activeName: '1',
      info: {},
    };
  },
  created() {
    this.$axios.post(this.$api.gateway.Ad.AdSet).then(res => {
      if (res.code == 0) {
        this.info = res.result;
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  methods: {
    preservation() {
      if (this.activeName == '1') {
        this.$axios
          .post(this.$api.gateway.Ad.editPrice, {
            type_1_price: this.info.type_1_price,
            type_1_show_number: this.info.type_1_show_number,
            type_2_price: this.info.type_2_price,
            type_2_show_number: this.info.type_2_show_number,
            type_3_price: this.info.type_3_price,
            type_3_show_number: this.info.type_3_show_number,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('保存成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        if (!this.info.explains) return this.$message.warning('请填写广告说明');
        this.$axios.post(this.$api.gateway.Ad.editExplains, { explains: this.info.explains }).then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  height: 100%;
  .tips {
    margin-bottom: 15px;
  }
  tbody {
    border: 1px solid #ebeef5;
    tr {
      td {
        border: 1px solid #ebeef5;
        padding: 10px;
      }
      .el-input {
        width: 240px;
        margin: 0 10px;
      }
      .price {
        display: flex;
        align-items: center;
      }
    }
  }
  thead tr th {
    padding: 15px;
    text-align: center;
  }
  thead tr {
    background: #f8f9fa;
    th {
      border: 1px solid #ebeef5;
    }
  }
}
</style>